var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card__custom card_pharmacy" },
    [
      _c("img", {
        staticClass: "ml-4 mr-2",
        attrs: {
          src: require("../../assets/images/farmacia-dark.png"),
          alt: "farmacia"
        }
      }),
      _c("b", [_vm._v(_vm._s(_vm.$t("proposal.Medicine.Pharmacies")) + ":")]),
      _c(
        "b-dropdown",
        {
          staticClass: "m-md-2",
          attrs: {
            id: "dropdown-right",
            text: _vm.pharmacySelected.label,
            variant: "outline-white"
          }
        },
        [
          _c("div", { staticClass: "header__dropdown" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/farmacia.png"),
                alt: "farmacia"
              }
            }),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("proposal.Medicine.Pharmacies")) + ":")
            ])
          ]),
          _vm._l(_vm.OptionsPharmacy, function(item, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                staticClass: "item__dropdown",
                on: {
                  click: function($event) {
                    return _vm.handleFarmacia(item)
                  }
                }
              },
              [_vm._v(_vm._s(item.label))]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }