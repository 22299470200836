export const NumberFormat = (number) => {

  let formato = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(number);

  return `${formato.slice(0, 1)} ${formato.slice(1)}`;
};
