<template>
  <div class="card__custom card_pharmacy">
    <img src="../../assets/images/farmacia-dark.png" alt="farmacia" class="ml-4 mr-2" />
    <b>{{ $t('proposal.Medicine.Pharmacies') }}:</b>
    <b-dropdown id="dropdown-right" :text="pharmacySelected.label" class="m-md-2" variant="outline-white">
      <div class="header__dropdown">
        <img src="../../assets/images/farmacia.png" alt="farmacia" />
        <p>{{ $t('proposal.Medicine.Pharmacies') }}:</p>
      </div>
      <b-dropdown-item
        class="item__dropdown"
        v-for="(item, index) in OptionsPharmacy"
        :key="index"
        @click="handleFarmacia(item)"
        >{{ item.label }}</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import { API_ROOT } from '../../Constants/Api.js';
import { AlertForm, ICON } from '../../helpers/Alert.js';
import { ApiRequest } from '../../helpers/ApiRequest.js';
export default {
  name: 'SelectPharmacy',
  data() {
    return {
      country: '',
      pharmacySelected: {
        label: `---${this.$i18n.t('medicine.select')}---`,
        value: null,
      },
      OptionsPharmacy: [],
    };
  },
  mounted() {
    this.country = sessionStorage.getItem('country');
    this.$store.dispatch('pharmacySelected', { label: '', value: null });
  },
  computed: {
    initialAppointmentBranchSelected() {
      return this.$store.state.sede_origen;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  watch: {
    language() {
      if (!this.pharmacySelected.value) this.pharmacySelected.label = `---${this.$i18n.t('medicine.select')}---`;
    },
    async initialAppointmentBranchSelected() {
      if (this.$store.state.sede_origen.country !== 'us') {
        try {
          const data = await ApiRequest.get(`${API_ROOT}farmacias/sede/${this.initialAppointmentBranchSelected.name}`);
          this.pharmacySelected = {
            label: `---${this.$i18n.t('medicine.select')}---`,
            value: null,
          };
          this.OptionsPharmacy = data.lista.map((el) => {
            return {
              label: el.nombre,
              value: { empresa: el.codigo, localidad: el.localidad },
            };
          });
          if (this.OptionsPharmacy.length) {
            this.handleFarmacia(this.OptionsPharmacy[0]);
          }
        } catch (err) {
          if (err.status === 401) {
            this.messageError = this.$i18n.t('admin.pharmacy.messageError');
            this.OptionsPharmacy = [];
          } else {
            AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.top.errorPharmacy'), ICON.ERROR);
          }
        }
      }
    },
  },
  methods: {
    handleFarmacia(item) {
      this.pharmacySelected = item;
      this.$store.dispatch('pharmacySelected', item);
    },
  },
};
</script>

<style>
@import '../../assets/css/global.css';
@import '../../assets/css/proposal.css';

/**** DropDown **** */
.card__custom .header__dropdown {
  display: flex;
  margin: 5px 10px;
  color: rgb(173, 181, 189);
  font-size: 1.2rem;
}
.card__custom .header__dropdown > img {
  padding-right: 6px;
}
.card__custom .item__dropdown {
  padding: 0 0.5rem 0 1rem;
  font-size: 1.2rem;
}

/*** Div pharmacy select */

.card__custom {
  background-color: var(--white);
}

.card_pharmacy {
  font-size: 1.5rem;
}

.card_pharmacy > img {
  width: 2.2rem;
}
</style>
