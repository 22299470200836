// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-2!../../assets/css/global.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-2!../../assets/css/proposal.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "\n/**** DropDown **** */\n.card__custom .header__dropdown {\n  display: flex;\n  margin: 5px 10px;\n  color: rgb(173, 181, 189);\n  font-size: 1.2rem;\n}\n.card__custom .header__dropdown > img {\n  padding-right: 6px;\n}\n.card__custom .item__dropdown {\n  padding: 0 0.5rem 0 1rem;\n  font-size: 1.2rem;\n}\n\n/*** Div pharmacy select */\n.card__custom {\n  background-color: var(--white);\n}\n.card_pharmacy {\n  font-size: 1.5rem;\n}\n.card_pharmacy > img {\n  width: 2.2rem;\n}\n", ""]);
// Exports
module.exports = exports;
